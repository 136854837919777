import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import { SANTANDER_AUTH } from '../graphql/mutations';
import { acceptedTermsVar, accessGrantedVar } from '../graphql/reactive-variables';
import { login as authLogin, logout as authLogout } from '../helpers/auth';
import informParent from '../helpers/post-message';
import { FAILED_ENROLLMENT_MESSAGE, SUCCESSFUL_ENROLLMENT_MESSAGE } from '../constants/enrollment';
import { GET_USER } from '../graphql/queries';

const useAuthentication = () => {
  const navigate = useNavigate();

  const accessGranted = useReactiveVar(accessGrantedVar);
  const [getUserQuery] = useLazyQuery(GET_USER, {
    onCompleted: ({ getUser }) => {
      const user = getUser;
      if (user?.selectedCompany?.hasLendingAcceptedterms) {
        acceptedTermsVar(true);
        if (user?.selectedCompany?.hasSiiCredentials) {
          if (accessGranted) {
            informParent(SUCCESSFUL_ENROLLMENT_MESSAGE);
            accessGrantedVar(false);
          }
        }
      } else {
        acceptedTermsVar(true);
      }
      navigate('enrollment');
    },
    onError: ({ message }) => {
      informParent(FAILED_ENROLLMENT_MESSAGE(message));
      authLogout();
      navigate('/enrollment/failed');
    },
  });

  const [santanderAuthMutation, { loading }] = useMutation(SANTANDER_AUTH, {
    onCompleted: ({ santanderAuth }) => {
      authLogin(santanderAuth);
      getUserQuery();
    },
    onError: ({ message }) => {
      informParent(FAILED_ENROLLMENT_MESSAGE(message));
      authLogout();
      navigate('/enrollment/failed');
    },
  });

  const login = useCallback(({ token }) => {
    santanderAuthMutation({ variables: { token } });
  }, []);

  const logout = useCallback(() => {
    authLogout();
  }, []);

  useEffect(() => {
    const handler = (event) => {
      const allowedOrigins = process.env.REACT_APP_ALLOWED_ORIGINS.split(',');
      if (!allowedOrigins.includes(event.origin)) {
        return;
      }
      const { data } = event;
      switch (data.type) {
        case 'LOGIN':
          login(data?.data);
          break;
        case 'LOGOUT':
          logout();
          break;
        default:
      }
    };
    window.addEventListener('message', handler);
    return () => {
      window.removeEventListener('message', handler);
    };
  }, []);

  return { loading };
};

export default useAuthentication;
