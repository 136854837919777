import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { FailedEnrollmentIcon, SuccessfulEnrollmentIcon } from '../assets/icons';

// Actually not used
export const ENROLMENT_TITLE = [
  { text: 'Ingresa con tu clave de SII para empezar a operar', color: 'secondary', variant: 'body2', letterSpacing: -1 },
];

export const ENROLLMENT_BODY = [
  {
    text: 'Para entregar una oferta adecuada para tu empresa, necesitaremos los datos actualizados.',
    sx: { mt: '4px' },
  },
  {
    text: 'Ingresa la clave SII de la empresa, para que a través de nuestra alianza con Fintech Fingo, analicemos tus datos y podamos ofrecerte mejores soluciones de financiamiento.',
  },
];

export const SUCCESSFUL_ENROLLMENT = {
  title: [
    { text: '¡Registro', color: 'secondary', variant: 'h1', letterSpacing: 0 },
    { text: 'exitoso!', color: 'primary', variant: 'h1', letterSpacing: 0 },
  ],
  body: 'Espere mientras procesamos la información. Esto puede tomar algunos minutos.',
  icon: <SvgIcon component={SuccessfulEnrollmentIcon} variant="resolution" inheritViewBox />,
};

export const SUCCESSFUL_ENROLLMENT_MESSAGE = { type: 'SUCCESS', message: 'Enrolamiento exitoso' };

export const FAILED_ENROLLMENT = {
  title: [{ text: '¡Oops! Algo salió mal', color: 'secondary', variant: 'h1', letterSpacing: 0 }],
  body: 'Estamos trabajando en ello. ¿Puedes volver más tarde? Gracias por tu paciencia.',
  icon: <SvgIcon component={FailedEnrollmentIcon} variant="resolution" inheritViewBox />,
};

export const FAILED_ENROLLMENT_MESSAGE = (message) => ({ type: 'FAILED', message });

export const ENROLLMENT_ERROR_MESSAGES = [
  { error: 'invalid sii credentials, try different ones', message: 'Credenciales inválidas' },
  { error: 'Could not retrieve cookies from Sii', message: 'Credenciales inválidas' },
  {
    error: 'Account temporarily blocked due to several incorrect attempts to login',
    message: 'Se excedió el límite de intentos',
  },
  { error: 'Request format is incorrect', message: 'Problemas de conexión con el SII' },
  { error: 'Error message not found.', message: 'Problemas de conexión con el SII' },
];

export const MOBILE_TYC_REDIRECT_TITLE = `Para registrate, debes revisar los Términos y condiciones
 de Fingo`;
