import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { acceptedTermsVar } from '../../graphql/reactive-variables';

const AcceptedTermsForm = () => {
  const checked = useReactiveVar(acceptedTermsVar);

  return (
    <FormGroup>
      <FormControlLabel
        control={(
          <Checkbox
            checked={checked}
            color="checkbox"
            inputProps={{ 'aria-label': 'controlled' }}
            onChange={(e) => {
              acceptedTermsVar(e.target.checked);
            }}
          />
          )}
        label="Acepto los Términos y Condiciones"
        color="secondary"
        componentsProps={{ typography: { variant: 'form' } }}
      />
    </FormGroup>
  );
};

export default AcceptedTermsForm;
