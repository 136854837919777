import React from 'react';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import EnrollmentForm from '../components/EnrollmentForm';
import { Title } from '../components/Layout';
import TermsAndConditionsDocument from '../components/TermsAndConditionsDocument';
import { TERMS_AND_CONDITIONS_TITLE } from '../constants/terms-and-conditions';
import AcceptedTermsForm from '../components/forms/AcceptedTermsForm';
import EnrollmentMobile from '../components/Enrollment/EnrollmentMobile';

const Enrollment = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('desktop'));

  return (
    isMobile ? (
      <EnrollmentMobile />
    ) : (
      <Container variant="primary">
        <Stack
          alignItems="center"
          justifyContent="center"
          direction="row"
          flex={1}
          height="100%"
        >
          <EnrollmentForm />
          <Stack
            alignItems="flex-start"
            justifyContent="center"
            justifyItems="center"
            sx={{ border: 'solid', borderColor: 'divider.main', borderWidth: '0 0 0 1px', px: 2 }}
            flex={3}
            height="100%"
          >
            <Title title={TERMS_AND_CONDITIONS_TITLE} alignSelf="flex-start" />
            <TermsAndConditionsDocument />
            <AcceptedTermsForm />
          </Stack>
        </Stack>
      </Container>
    )
  );
};

export default Enrollment;
